<template>
	<!-- eslint-disable -->
	<v-container
		v-if="hasPermission('Best Sellers')"
		id="dashboard"
		fluid
		tag="section"
	>
		<v-row>
			<v-col cols="12">
				<v-card class="px-8">
					<v-row>
						<v-col cols="12">
							<v-card class="py-0" flat>
								<v-card-title class="display-2 font-weight-light mb-4">
									<v-row>
										<v-col cols="12">
											<v-label>
												<h1 style="color: grey">Filters</h1>
											</v-label>
										</v-col>
									</v-row>
								</v-card-title>

								<v-card-text>
									<v-row>
										<v-col cols="3">
											<v-dialog
												ref="dialog"
												v-model="modal"
												:return-value.sync="filterDateRange"
												persistent
												width="290px"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														v-model="dateRangeText"
														readonly
														range
														class="white"
														dense
														label="Date"
														outlined
														hide-details
														v-bind="attrs"
														v-on="on"
													>
														<template v-slot:prepend-inner
															><v-icon>mdi-calendar</v-icon></template
														>
													</v-text-field>
												</template>
												<v-date-picker
													v-model="filterDateRange"
													scrollable
													range
													@change="filterDateRangeChanged($event)"
												>
													<v-spacer></v-spacer>
													<v-btn text color="primary" @click="modal = false">
														Cancel
													</v-btn>
													<v-btn
														text
														color="primary"
														@click="$refs.dialog.save(filterDateRange)"
													>
														OK
													</v-btn>
												</v-date-picker>
											</v-dialog>
										</v-col>
										<v-col cols="3">
											<v-autocomplete
												v-model="selectedBranch"
												:items="branchList"
												outlined
												dense
												chips
												small-chips
												label="Branches"
												item-text="name"
												item-value="id"
												@change="branchChanged"
											></v-autocomplete>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12">
				<v-card class="px-8 pb-8 pt-2">
					<v-row>
						<v-col cols="12">
							<v-card class="py-0" flat>
								<v-card-title class="display-2 font-weight-light mb-4">
									<v-row>
										<v-col cols="6">
											<v-label>
												<h1 style="color: grey">Best seller last month</h1>
											</v-label>
										</v-col>
									</v-row>
								</v-card-title>

								<v-card-text>
									<v-row>
										<v-col cols="12">
											<v-radio-group
												v-model="best_seller_by"
												row
												@change="sellerByChanged($event)"
											>
												<v-radio label="Orders" value="1"></v-radio>
												<v-radio label="Sales" value="2"></v-radio>
											</v-radio-group>
										</v-col>
										<v-col cols="12">
											<v-simple-table id="dashboard-best-seller-table">
												<template v-slot:default>
													<thead>
														<tr>
															<th class="text-left">ID</th>
															<th class="text-left">Name</th>
															<!-- <th class="text-left">This Month</th> -->
															<th class="text-left">Total Orders</th>
															<th class="text-left">Total Orders Sales</th>
														</tr>
													</thead>
													<tbody>
														<tr
															v-for="(seller, index) in best_sellers"
															:key="index"
														>
															<td>{{ seller.id }}</td>
															<td>{{ seller.name }}</td>
															<!-- <td>{{ current_month }}</td> -->
															<td>{{ seller.seller_count }}</td>
															<td>{{ seller.partner_total_sale_with_vat }}</td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
	<v-container fluid v-else class="pa-10 " id="regular-tables" tag="section">
		<v-row>
			<v-col cols="12" class="d-flex justify-center">
				<v-card flat>
					<v-card-title
						><v-card class="red">
							<h1 class="red pa-6">
								You Don't have Permission to view this page !
							</h1></v-card
						></v-card-title
					>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import moment from "moment";
export default {
	data() {
		return {
			filterFromDialog: false,
			filterToDialog: false,
			filterDateRange: [],
			date: null,
			from: null,
			to: null,
			modal: false,
			selectedBranch: 0,
			user_id: localStorage.getItem("userid"),
			token: localStorage.getItem("token"),
			best_sellers: []
		};
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	computed: {
		dateRangeText() {
			// return `from ${this.filterDateRange[0]} to ${this.filterDateRange[1]}`;
			return this.filterDateRange.join(" to ");
		}
	},
	watch: {},

	created() {
		
		this.getBestSeller();
		this.getBranches();
		this.interval = setInterval(() => {
			if (this.value === 100) {
				return (this.value = 0);
			}
			this.value += 10;
		}, 1000);
	},
	methods: {
		sellerByChanged(e) {
			if (e == 1) {
				this.best_sellers = this.best_sellers_by_orders;
			} else {
				this.best_sellers = this.best_sellers_by_sales;
			}
		},
		getBestSeller() {
			axios
				.get("/api/v3/b2b/best-seller", {
					headers: { "X-Authorization": this.token },
					params: {
						from: this.from,
						to: this.to,
						user_id: this.user_id,
						branch_id: this.selectedBranch
					}
				})
				.then(res => {
					this.filterDateRange = [res.data.from_date, res.data.to_date];
					this.best_sellers_by_orders = res.data.best_sellers_by_orders;
					this.best_sellers_by_sales = res.data.best_sellers_by_sales;

					this.best_sellers = this.best_sellers_by_orders;
				})
				.catch(err => console.log(err.response.data));
		},
		getBranches() {
			axios
				.get("/api/v3/b2b/partner/branches-list", {
					headers: { "X-Authorization": this.token },
					params: {
						// partner_id:this.parent_id == 0 ?  this.user_id : this.parent_id,
						// seller_id:  this.parent_id == 0 ?  null : this.user_id ,
						user_id: this.user_id
					}
				})
				.then(res => {
					this.branchList = res.data.branches;
					if (this.branchList.length > 0) {
						this.selectedBranch = this.branchList[0].identifier;
					}
				})
				.catch(err => console.log(err.response.data));
		},
		filterDateRangeChanged(newDateRange) {
			if (newDateRange.length == 1) {
				this.from = newDateRange[0];
			} else if (newDateRange.length == 2) {
				this.from = newDateRange[0];
				this.to = newDateRange[1];
			}
			this.getBestSeller();
		},
		filterDateChange(e) {
			this.from = this.filterFrom;
			this.to = this.filterTo;
			this.getBestSeller();
		},
		branchChanged() {
			this.getBestSeller();
		}
	}
};
</script>
<style scoped>
#dashboard-best-seller-table th {
	color: #000 !important;
}
.v-progress-circular {
	margin: 1rem;
}
.lab,
p {
	color: #ffffff !important;
	font-size: 16px;
}
</style>
